import React, { useContext } from "react";
import { Page, PageLink } from "bloomer";
import { PageContext } from "../App";

interface PageIndexProps {
  index: number;
}
const PageIndex: React.FunctionComponent<PageIndexProps> = ({
  index
}) => {
  const { page, setPage } = useContext(PageContext);
  return (
    <Page>
      <PageLink isCurrent={page === index} onClick={() => setPage(index)}>
        {index}
      </PageLink>
    </Page>
  );
};

export default PageIndex
