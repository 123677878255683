import { APIKEY } from "./config";
import { Paging } from "./types";

export const fetchSingle = <T>(path: string): Promise<T> =>
  fetchCollection<T>(path).then(({results}) => results[0])

export const fetchCollection = <T>(path: string): Promise<Paging<T>> => 
  fetch(addApiKey(`https://gateway.marvel.com/v1/public/${path}`))
  .then(res => res.json())
  .then(res => res.data)

const addApiKey = (uri: string) =>
  uri.concat(uri.includes("?") ? "&" : "?", `apikey=${APIKEY}`);
