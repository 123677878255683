import React, { createContext, useContext } from "react";
import {
  Image,
  Columns,
  Column,
  Card,
  CardHeader,
  CardHeaderTitle,
  CardImage,
  CardFooter,
  CardFooterItem,
  Title
} from "bloomer";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { CharacterDetails } from "../types";
import { fetchCollection } from "../api";
import { PageContext } from "../App";
import Paging from "../components/Paging";

const LIMIT = 20;
const getCharacters = (page: number) =>
  fetchCollection<CharacterDetails>(`characters?offset=${page * LIMIT}&limit=${LIMIT}`);

const Landing: React.SFC = () => {
  const [characters, setCharacters] = useState<CharacterDetails[]>([]);
  const { page } = useContext(PageContext);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setLoading] = useState(false);
  useEffect(
    () => {
      setLoading(true);
      getCharacters(page - 1).then(pagingData => {
        setCharacters(pagingData.results);
        setTotalPages(Math.ceil(pagingData.total / LIMIT));
        setLoading(false);
      });
    },
    [page]
  );
  return (
    <>
      <Title>Marvel's Characters</Title>
      {totalPages !== 0 && (
        <Paging currentPage={page} totalPages={totalPages} />
      )}
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <Columns isMultiline>
          {characters.map(c => (
            <Column isSize="1/4" key={c.id}>
              <Card>
                <CardHeader>
                  <CardHeaderTitle>{c.name}</CardHeaderTitle>
                </CardHeader>
                <CardImage>
                  <Image src={`${c.thumbnail.path.replace('http', 'https')}.${c.thumbnail.extension}`} />
                </CardImage>
                <CardFooter>
                  <CardFooterItem
                    render={(props: any) => (
                      <Link to={`/characters/${c.id}`} {...props}>
                        Details
                      </Link>
                    )}
                  />
                </CardFooter>
              </Card>
            </Column>
          ))}
        </Columns>
      )}
    </>
  );
};

export default Landing;
