import React from "react";
import { Pagination, PageList, Page, PageEllipsis } from "bloomer";
import PageIndex from "./PageIndex";

interface PagingProps {
  currentPage: number;
  totalPages: number;
}
const Paging: React.FunctionComponent<PagingProps> = props => {
  return (
    <Pagination>
      <PageList>
        <PageIndex index={1} />
        {props.currentPage > 3 && (
          <Page>
            <PageEllipsis />
          </Page>
        )}
        {props.currentPage > 2 && <PageIndex index={props.currentPage - 1} />}
        {props.currentPage > 1 && <PageIndex index={props.currentPage} />}
        {props.totalPages - props.currentPage >= 2 && (
          <PageIndex index={props.currentPage + 1} />
        )}
        {props.totalPages - props.currentPage >= 3 && (
          <Page>
            <PageEllipsis />
          </Page>
        )}
        {props.totalPages !== props.currentPage && (
          <PageIndex index={props.totalPages} />
        )}
      </PageList>
    </Pagination>
  );
};

export default Paging