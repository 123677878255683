import React from "react";
import { Panel, PanelHeading, PanelBlock } from "bloomer";

import { Summary } from "../types";

const PanelList: React.FunctionComponent<{
  title: string;
  list: Summary[];
}> = props => (
  <Panel>
    <PanelHeading>{props.title}</PanelHeading>
    {props.list.map(summary => (
      <PanelBlock key={summary.name}>{summary.name}</PanelBlock>
    ))}
  </Panel>
);

export default PanelList
