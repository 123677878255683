import React, { createContext, useState } from "react";
import { Router, Switch, Route, Redirect } from "react-router";
import { Container, Section, Footer, Content } from "bloomer";
import { createBrowserHistory } from "history";
import Landing from "./pages/Landing";
import Character from "./pages/Character";

export const PageContext = createContext({page: 1, setPage: (_page: number) => {}})

const history = createBrowserHistory();
const App: React.SFC = () => {
  const [page, setPage] = useState(1)
  return (
    <Router history={history}>
      <div
        style={{ display: "flex", flexDirection: "column", height: "100vh" }}
      >
        <Container>
          <Section>
            <PageContext.Provider value={{page, setPage}}>
              <Switch>
                <Route exact path="/" component={Landing} />
                <Route path="/characters/:id" component={Character} />
                <Redirect to="/" />
              </Switch>
            </PageContext.Provider>
          </Section>
        </Container>
        <div style={{ flexGrow: 2 }} />
        <Footer>
          <Content hasTextAlign="centered">
            <a href="https://marvel.com">
              Data provided by Marvel. © 2018 MARVEL
            </a>
          </Content>
        </Footer>
      </div>
    </Router>
  );
};

export default App;
