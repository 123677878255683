import React, { useState, useEffect } from "react";
import { RouteComponentProps } from "react-router";
import {
  Title,
  Button,
  Media,
  MediaLeft,
  Image,
  MediaContent,
  Content,
  Columns,
  Column
} from "bloomer";
import { Link } from "react-router-dom";

import { CharacterDetails } from "../types";
import PanelList from "../components/PanelList";
import { fetchSingle } from "../api";

const getCharacterDetails = (id: string) =>
  fetchSingle<CharacterDetails>(`characters/${id}`);

const Character: React.FunctionComponent<
  RouteComponentProps<{ id: string }>
> = props => {
  const [character, setCharacter] = useState<CharacterDetails | null>(null);
  const [isLoading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    setLoading(true);
    getCharacterDetails(props.match.params.id)
      .then(setCharacter)
      .then(() => setLoading(false));
  }, []);
  if (isLoading || !character) {
    return <p>Loading...</p>;
  }
  return (
    <>
      <Media>
        <MediaLeft>
          <Image
            isSize={"128x128"}
            src={`${character.thumbnail.path.replace('http', 'https')}.${character.thumbnail.extension}`}
          />
        </MediaLeft>
        <MediaContent>
          <Title>{character.name}</Title>
          <Button
            render={(props: any) => (
              <Link to="/" {...props}>
                Back
              </Link>
            )}
          />
        </MediaContent>
      </Media>
      <hr />
      <Content>
        {character.description && (
          <>
            <h1>Description</h1>
            <p>{character.description}</p>
          </>
        )}
      </Content>
      <Columns>
        <Column isSize="1/4">
          <PanelList title="Comics" list={character.comics.items} />
        </Column>
        <Column isSize="1/4">
          <PanelList title="Stories" list={character.stories.items} />
        </Column>
        <Column isSize="1/4">
          <PanelList title="Events" list={character.events.items} />
        </Column>
        <Column isSize="1/4">
          <PanelList title="Series" list={character.series.items} />
        </Column>
      </Columns>
    </>
  );
};

export default Character;
